import { Box, CommonButton, CustomTyphography, Flexbox, Typography } from 'components'
import { CloseIcon, ContentCopyIcon, Stars } from 'components/icons'
import PRDPreviewItem from './PRDPreviewItem'
import { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind';
import styles from '../assistant.module.scss';
import { AIAnswerTypes, PRDMainType } from 'utils/types';
import { returnMainContent } from '..';

const classes = classNames.bind(styles);

interface PRDPreviewPanelProps {
    onClose: () => void,
    messageType?: AIAnswerTypes,
    content?: PRDMainType,
    currentSection?: string,
    setAiRefineDialog: (arg: {
        open: boolean,
        field: string
    }) => void
}

const PRDPreviewPanel: FC<PRDPreviewPanelProps> = ({ onClose, content, setAiRefineDialog, messageType, currentSection }) => {
    const prdPreviewRef = useRef<HTMLDivElement>(null);
    const [isCopied, setIsCopied] = useState(false)

    const openAiRefineDialog = (key: string) => {
        setAiRefineDialog({
            open: true,
            field: key
        })
    }

    const handleCopyPRD = () => {
        const contentElement = document.querySelector('#copy_content');

        if (!contentElement) {
            console.error('Content element not found');
            return;
        }

        const elementsToExclude = contentElement.querySelectorAll<HTMLElement>('[data-copy="false"]');
        elementsToExclude.forEach(el => el.style.display = 'none');

        const range = document.createRange();
        range.selectNodeContents(contentElement);
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
            try {
                document.execCommand('copy');
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 1000)
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }

            selection.removeAllRanges();
        }

        elementsToExclude.forEach(el => el.style.display = '');
    };

    useEffect(() => {
        if (prdPreviewRef.current) {
            if (messageType === AIAnswerTypes.PRD_PREVIEW_STREAM) {
                prdPreviewRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
            } else if (messageType === AIAnswerTypes.PRD_PREVIEW && !currentSection) {
                prdPreviewRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, [messageType, currentSection, content, prdPreviewRef.current]);

    return (
        <Box className={classes('prdPreviewSidePanel')} id='copy_content' >
            <Flexbox justifyBetween className={classes('prdPreview-header')}>
                <Typography className={classes('prdPreview-title')}>{content?.title ? content?.title : 'PRD Preview'}</Typography>
                <Flexbox data-copy='false'>
                    <CloseIcon className={classes('closeIcon')} onClick={onClose} />
                </Flexbox>
            </Flexbox>
            <Box className={classes('prdPreview-mainContent')}>
                <CommonButton
                    buttonType='shadow'
                    data-copy='false'
                    buttonSize='small'
                    className={classes('copyIcon', 'mb-2')}
                    onClick={handleCopyPRD}
                >
                    {isCopied ? 'Copied!' : 'Copy PRD'}
                    <ContentCopyIcon />
                </CommonButton>
                <Flexbox fullWidth vertical className={classes('gap-4')} ref={prdPreviewRef}>
                    {content && Object.entries(returnMainContent(content, currentSection)).map(([key, value]) => (
                        <Flexbox key={key} vertical className={classes('gap-2')}>
                            <Flexbox align justifyBetween>
                                <Flexbox className={classes('prdFieldLabel')}>{key}</Flexbox>
                                {
                                    messageType === AIAnswerTypes.PRD_PREVIEW && (
                                        <Flexbox align
                                            className={classes('cursor-pointer', 'gap-1', 'refine-btn')}
                                            onClick={() => openAiRefineDialog(key)}
                                            data-copy='false'
                                        >
                                            <Stars />
                                            <CustomTyphography>AI Refine</CustomTyphography>
                                        </Flexbox>
                                    )
                                }
                            </Flexbox>
                            {
                                value !== null && <PRDPreviewItem value={value} />
                            }
                        </Flexbox>
                    ))}
                </Flexbox>
            </Box>
        </Box>
    )
}

export default PRDPreviewPanel